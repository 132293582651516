import React from "react";
import './index.scss';

const Header = ({
    children
}) => {
    return (
        <div className="rug-header">
            {children}
        </div>
    )
}

export default Header;