import React, { useState, useEffect, useRef } from "react";
import Continue from "src/components/SvgIcon/Continue";
import { GoPlay } from "goplay-ws/src/pkg.pb";

import "./index.scss";
import classNames from "classnames";
import DataViewTools from "src/views/DataView/DataViewTools";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useLogin } from "src/App";
import { Show } from "src/utils";

const Checking = ({ visible, setIsFirstTimeLogin }) => {
  const { t } = useTranslation();
  const [amazing, setAmazing] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [coinNumber, setCoinNumber] = useState(undefined);
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const steps = [
    {
      label: t("Checking.Analyzing_your_account"),
      value: 0,
    },
    {
      label: <Trans i18nKey={"Checking.Account_ID"} values={{ id }}></Trans>,
      value: 1,
    },
    {
      label: t("Checking.Estimating_your_account_value"),
      value: 2,
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatus, setStepStatus] = useState({
    0: "pending",
    1: "pending",
    2: "pending",
  });

  const dataTools = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    dataTools.current = new DataViewTools();
   
  }, []);

  const onSeeResult = async () => {
    setAmazing(true);
    const { status, data } = await dataTools.current.Coin_NewUserReward();

    console.log("Coin_NewUserReward", status, data);
    Show(status);
    if (status.Code === GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("Coin_NewUserReward_callback", status, data);
      setCoinNumber(data.Coin);
      setIsPremium(data.IsPremium);
    }
  };

  useEffect(() => {
    if (stepStatus[steps[currentStep].value] === "pending") {
      const timer = setTimeout(() => {
        setStepStatus((prevState) => ({
          ...prevState,
          [steps[currentStep].value]: "success",
        }));
      }, 1000); // 3 seconds

      return () => clearTimeout(timer);
    }
  }, [currentStep, stepStatus, steps]);

  useEffect(() => {
    // 当前步骤成功后，自动进入下一步
    if (
      stepStatus[steps[currentStep].value] === "success" &&
      currentStep < steps.length - 1
    ) {
      setCurrentStep(currentStep + 1);
    }
  }, [stepStatus, currentStep, steps]);

  const onContinue = () => {
    window.isFirTime = true;
    setIsFirstTimeLogin();
    navigate("/trade");
  };

  return (
    <div
      className={classNames("app-page-landing", {
        ["app-page-landing-visible"]: visible,
      })}
    >
      <div className="page-landing-logo"></div>
      <div
        className={classNames("analysis-step", {
          hidden: amazing,
        })}
      >
        {steps.map((v, index) => (
          <div key={v.value} className="analysis-step-item">
            {stepStatus[v.value] === "pending" ? (
              <div className="loader"></div>
            ) : (
              <div className="checked-icon"></div>
            )}
            <span className="text">{v.label}</span>
          </div>
        ))}
      </div>
      {amazing && !isPremium && (
        <div className="amazing-text">
          {t("Checking.You_are_amazing")}!
        </div>
      )}
      {amazing && isPremium && (
        <div className="amazing-text">
          {t("Checking.You_are_premuim")}
          <span className="img-premium" />!
        </div>
      )}

      {amazing && (
        <div className="thanks-text">
          {t("Checking.Thank_you_for_your_time_on_Telegram")}
        </div>
      )}
      {amazing && (
        <div className="coin">
          <div className="usdt-img"></div>
          {amazing && coinNumber !== undefined && (
            <div className="coin-number-text">{coinNumber} USDT</div>
          )}
        </div>
      )}
      {stepStatus[2] === "success" && !amazing && (
        <div
          className="see-result-btn"
          style={{ color: "#333" }}
          onClick={onSeeResult}
        >
          {t("Checking.See_Result")}
        </div>
      )}
      {amazing && (
        <div className="see-result-btn" onClick={onContinue}>
          {t("Checking.Continue")} <Continue />
        </div>
      )}
    </div>
  );
};

export default Checking;
