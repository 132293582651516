import React from "react";

const ArrowDown = (props) => {
    return (
        <span {...props} style={{ lineHeight: 0 }}>
            <svg width="9" height="5" viewBox="0 0 9 5" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.106 4.839.164.94a.547.547 0 0 1 0-.78.562.562 0 0 1 .788 0L4.5 3.67 8.048.161a.562.562 0 0 1 .789 0 .547.547 0 0 1 0 .78L4.894 4.84a.563.563 0 0 1-.788 0z" fill="#CBCED2" fill-rule="nonzero" />
            </svg>
        </span>
    )
}

export default ArrowDown;