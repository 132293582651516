import React, { useTransition } from "react";

import './style.scss';
import classNames from "classnames";
import { ShareBtn } from "src/views/Trade/components/ShareDrawer";
import { Rugbull } from "src/network/generated/pkg.pb";
import { useTranslation } from "react-i18next";
import { useNotification } from "rc-notification";
import { GetCoinName, GetCoinPic } from "src/utils";

interface Props {
    data: Rugbull.Protocols.SCGetTrade
    handlecopy:()=>void,
    isShare: any | null,
    ShareUrl: string
}

const ReplayBottomInfo: React.FC<Props> = ({data,handlecopy,isShare,ShareUrl}) => {
    const { t } = useTranslation();
    const [{ open, close }, holder] = useNotification();
    const GetCoinPic1 = (coinType: Rugbull.Protocols.CoinType) => {
        return coinType === Rugbull.Protocols.CoinType.CoinType_USDT ? 'USDT' : 'T';
    }

    return (
        <div className="replayer-bottom-info">
            {isShare && <div className="title-bar">
                <div className="avatar">
                    <img src={data.PhotoUrl} alt="" />
                </div>
                <div className="user-name">{data.UserName}</div>
            </div>}
            <div className={isShare?'info-content':'info-content1'}>
                <div className="title">{t('Replay.Profit')}</div>
                <span className={classNames("icon", GetCoinPic1(data.CoinType))}></span>
                <div className="usdt-number">
                    <span className="number">
                        +{data.WinAmount}&nbsp;
                    </span>
                    {GetCoinName(data.CoinType)}
                </div>
                <div className="price-info">
                    <div className="price-info-item pw-2">
                        <div className="price-info-item-title">{t('TopContent.Buy_Price')}</div>
                        <div className="price-info-item-bottom">
                            <span className="number">{data.Amount} {GetCoinName(data.CoinType)}</span>
                        </div>
                    </div>
                    <div className={classNames("price-info-item pw-1")} >
                        <div className="price-info-item-title">{t('Replay.Sell')}</div>
                        <div className="price-info-item-bottom">
                            <span className="number sell"><div className={classNames("rate", {})}>{data.Multiplier}x</div></span>
                        </div>
                    </div>
                    <div className="price-info-item pw-2">
                        <div className="price-info-item-title">{t('TopContent.Sell_Price')}</div>
                        <div className="price-info-item-bottom">
                            <span className="number sell">{data.WinAmount} {GetCoinName(data.CoinType)}</span>
                        </div>
                    </div>
                </div>
                
            </div>
            {isShare&&
            <div className="trade-content">
                <button className="btn" onClick={()=>{window.location.href = ShareUrl}}>{t('App.Trade')}</button>
                <div className="tips">
                    {t('Replay.NoLimit')} <span className="number">x1000</span>
                </div>
            </div>
            }
            
        </div>
    )
}

export default ReplayBottomInfo;