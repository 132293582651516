import React from "react";

const Continue = (props) => {
    return (
        <span {...props} className="svg-icon">
            <svg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4.5h10.088L6.288.712 7 0l4.287 4.287L12 5l-.713.713L7 10l-.713-.713 3.8-3.787H0z" fill="#000" fill-rule="nonzero" />
            </svg>
        </span>
    )
}

export default Continue;