import React from "react";

const CopyBtn = (props) => {
    return (
        <span {...props} style={{lineHeight: 0}}>
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.033 1.02a1.204 1.204 0 0 0-.85-.353H6.154a1.205 1.205 0 0 0-1.21 1.207V3.04H2.817a1.204 1.204 0 0 0-1.204 1.204v9.884a1.205 1.205 0 0 0 1.207 1.204h7.654a1.205 1.205 0 0 0 1.222-1.204v-2.157h1.47a1.204 1.204 0 0 0 1.22-1.198V1.87c0-.319-.128-.625-.354-.85zM9.558 9.374c0 .306-.152.458-.458.458H7.114v1.986c0 .306-.153.459-.458.459H6.35c-.306 0-.458-.153-.458-.459V9.833H3.906c-.306 0-.459-.153-.459-.458v-.306c0-.305.153-.458.459-.458h1.986V6.625c0-.306.153-.458.458-.458h.306c.305 0 .458.152.458.458V8.61H9.1c.306 0 .458.153.458.458v.306zm3.667 1.097c0 .178-.13.33-.306.36h-1.222V4.245a1.204 1.204 0 0 0-1.222-1.204h-4.39v-.752c0-.27.218-.489.488-.489h6.163c.27 0 .489.22.489.49v8.182z" fill="#7A8088" fill-rule="nonzero" />
            </svg>
        </span>
    )
}

export default CopyBtn;