import React from "react";

import type { Props as ModalProps } from "src/components/Modal";
import Modal from "src/components/Modal";

import "./index.scss";
import { useTranslation } from "react-i18next";

type Props = Pick<ModalProps, "open" | "onCancel" | "onOk"> & {};

const InsBalanceModal: React.FC<Props> = ({ open, onCancel, onOk }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("Trade.InBalanceModal.title")}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="insBalance-modal"
    >
      <div className="insBalance-modal-content">
        <div className="text"> {t("Trade.InBalanceModal.text")}</div>
        <div className="btn" onClick={onOk}>{t("Trade.InBalanceModal.Deposit")}</div>
      </div>
    </Modal>
  );
};

export default InsBalanceModal;
