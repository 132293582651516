import React from "react";

const NoticationClose = (props) => {
    return (
        <span className="svg-icon" {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm4.288-12.992a.917.917 0 0 0-1.296-1.296L10 8.704 7.008 5.712a.917.917 0 1 0-1.296 1.296L8.704 10l-2.992 2.992a.917.917 0 1 0 1.296 1.296L10 11.296l2.992 2.992a.917.917 0 0 0 1.296-1.296L11.296 10l2.992-2.992z" fill="#000" fill-rule="nonzero" opacity=".3" />
            </svg>
        </span>
    )
}

export default NoticationClose;