import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "rc-switch/assets/index.css";
import "./rc-notification.scss";
import "./index.scss";
import "./icon.scss";
import './index.css';
// import App from './Layout';
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import "./utils/i18n";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <TonConnectUIProvider 
  manifestUrl="https://rugbull.tma.how2d.com/manifest.json"
  actionsConfiguration={{
    twaReturnUrl: 'https://t.me/xpumpai_bot/game'
}}>
           <RouterProvider router={router} />
  </TonConnectUIProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let tg = window.Telegram.WebApp;
tg.expand();
tg.enableClosingConfirmation();
tg.onEvent("viewportChanged", (e) => {
  console.log(
    "isStateStable,viewportStableHeight,viewportHeight",
    e,
    tg.viewportStableHeight,
    tg.viewportHeight
  );
  if (e && tg.viewportStableHeight !== tg.viewportHeight) {
    tg.expand();
  }
});
tg.ready();
