import React, { useState, useEffect } from "react";
import classNames from "classnames";
import CloseIcon from "src/components/SvgIcon/CloseIcon";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { Rugbull } from "src/network/generated/pkg.pb";

export const Radio = ({ checked }) => {
  if (checked) {
    return (
      <svg
        className="svg-radio"
        width="auto"
        height="auto"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <circle stroke="#E8BA41" cx="6" cy="6" r="5.5" />
          <circle fill="#E8BA41" cx="6" cy="6" r="3" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        className="svg-radio"
        width="auto"
        height="auto"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <circle stroke="#7A8088" cx="6" cy="6" r="5.5" />
          <circle fill="#7A8088" cx="6" cy="6" r="3" />
        </g>
      </svg>
    );
  }
};

const CurrencyChose = ({ visible, isLogined, onClose, current, dataSource, onSelect }) => {
  const { t } = useTranslation();
  const [selectValues, setSelectValues] = useState("");
  const tools = new DataViewTools();
  const User_UpdateLanguage = async (language) => {
    let { status } = await tools.User_UpdateLanguage(
      Rugbull.Protocols.CSUpdateLanguage.create({ Language: language })
    );
    if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("User_UpdateLanguage Error");
      return;
    }
  };

  useEffect(() => {
    setSelectValues(current);
  }, [current]);

  const _onSelect = (v) => {
    setSelectValues(v.value);
  };

  const onConfirm = () => {
    if (onSelect) {
      changeLanguage(selectValues);
      onSelect(selectValues);

      if (isLogined) User_UpdateLanguage(selectValues);
    }
  };

  return (
    <div
      className={classNames("rug-chose-currency", {
        visible: visible,
      })}
    >
      <div className="modal-mask"></div>
      <div className="modal-content">
        <CloseIcon onClick={onClose} />
        <div className="title">{t("CurrencyChose.Language")}</div>
        <ul className="currency-list">
          {dataSource.map((v) => {
            return (
              <li
                key={v.value}
                className={classNames("item", {
                  active: selectValues === v.value,
                })}
                onClick={() => {
                  let item = v;
                  _onSelect(item);
                }}
              >
                <Radio checked={selectValues === v.value} />
                <span className="name">{v.label}</span>
              </li>
            );
          })}
        </ul>
        <div className="confirm-btn" onClick={onConfirm}>
          {t("CurrencyChose.Confirm")}
        </div>
      </div>
    </div>
  );
};

export default CurrencyChose;
