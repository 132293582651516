import React from "react";
import classNames from "classnames";
import { colors } from "src/utils";
import "./rug-pull.scss";
import { useTranslation } from "react-i18next";

const RugPull = ({ number, visible }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames("rug-pull", {
        active: visible,
      })}
    >
      <div className="text">RUG PULL!</div>
      <div
        className="current-pull-number"
        style={{
          // color: colors(number)
          color: "#ff0000",
        }}
      >
        {number}x
      </div>
    </div>
  );
};

export default RugPull;
