import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import LanguageChose from "src/components/CurrencyChose/LanguageChose";
import ArrowDown from "../Icon/ArrowDown";
import CurrencyChose from "../CurrencyChose";
import { Rugbull } from "src/network/generated/pkg.pb";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { GetCoinName, GetCoinPic, Show } from "src/utils";
import { useLogin } from "src/App";
import { set } from "animejs";
import { useNavigate } from "react-router-dom";

// 可选的语言集合
const languageData = [
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "العربية",
    value: "ar-SA",
  },
  {
    label: "বাংলা",
    value: "bn-BD",
  },
  {
    label: "español",
    value: "es-ES",
  },
  {
    label: "Filipino",
    value: "fil-PH",
  },
  {
    label: "français",
    value: "fr-FR",
  },
  {
    label: "हिंदी",
    value: "hi-In",
  },
  {
    label: "Bahasa Indonesia",
    value: "id-ID",
  },
  {
    label: "日本語",
    value: "ja-JP",
  },
  {
    label: "한국인",
    value: "ko-KR",
  },
  {
    label: "Bahasa Melayu",
    value: "ms-MY",
  },
  {
    label: "မြန်မာဘာသာ",
    value: "my-MM",
  },
  {
    label: "português ",
    value: "pt-PT",
  },
  {
    label: "русский ",
    value: "ru-RU",
  },
  {
    label: "ภาษาไทย",
    value: "th-Th",
  },
  {
    label: "Tiếng Việt",
    value: "vi-VN",
  },
  {
    label: "中文（简体）",
    value: "zh-SG",
  },
  {
    label: "中文（繁体）",
    value: "zh-TW",
  },
];

const HeaderTopContent = ({
  loginData=null, 
  currentLanguage=null, 
  setCurrentLanguage=null, 
  currentSelectCurrency=null, 
  setCurrentSelectCurrency=null,
  openModal = () => {},
  isShare=null,
  visable = false
}) => {
  const tools = new DataViewTools();

  const [Coindatas, setCoindatas] = useState([]);
  const [ curLang, setCurLang ] = useState(languageData[0].value);

  const isLogined = loginData?.User?.Id ? true : false;
  if (currentLanguage === null) {
    currentLanguage = curLang;
    setCurrentLanguage = setCurLang;
  }
  
  const Coin_GetCoinInfos = async () => {
    let { status, data } = await tools.Coin_GetCoinInfos();
    if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("Coin_GetCoinInfos Error");
      Show(status);
      return;
    }
    setCoindatas(data.CoinInfos);
  };
  // 语言选择弹框
  const [LanguageVisiblevisible, setLanguageVisible] = useState(false);
  // 货币选择框
  const [CurrencyVisiblevisible, setCurrencyVisible] = useState(false);

  const navigate = useNavigate();
  console.log('Share...',isShare,isLogined);

  return (
    <>
      <div className={classNames("header-top-content")}>
        {
          (!isLogined && !isShare)? (
            <div className="back-btn" onClick={() => navigate('/')}>
              <i className="back-icon"></i>                
            </div>
          ) : null
        }
        <div className="logo-wrap">
          <div className="logo"></div>
          {isLogined && <div className="help" onClick={openModal}></div>}
        </div>
        <div className="header-top-content-right">
          {visable && <div
            className="select"
            onClick={() => {
              setCurrencyVisible(true);
              Coin_GetCoinInfos();
            }}
          >
            <span
              className={classNames("icon " + GetCoinPic(currentSelectCurrency.Type))}
            ></span>
            {/* <span className={classNames('icon', currentSelectCurrency.value)}></span> */}
            <span className="select-label">{currentSelectCurrency.Coin}</span>
            <span className="drop-down-icon">
              <ArrowDown></ArrowDown>
            </span>
          </div>}
          <div
            className="language-btn"
            onClick={() => setLanguageVisible(true)}
          >
            {currentLanguage}
          </div>
        </div>
      </div>
      <LanguageChose
        visible={LanguageVisiblevisible}
        isLogined={isLogined}
        onClose={() => {
          setLanguageVisible(false);
        }}
        current={currentLanguage}
        dataSource={languageData}
        onSelect={(v) => {
          setCurrentLanguage(v);
          setLanguageVisible(false);
        }}
      />
      {isLogined && <CurrencyChose
        visible={CurrencyVisiblevisible}
        onClose={() => {
          setCurrencyVisible(false);
        }}
        current={currentSelectCurrency}
        dataSource={Coindatas}
        onSelect={(v) => {
          setCurrentSelectCurrency(v);
          setCurrencyVisible(false);
        }}
      />}
    </>
  );
};

export default HeaderTopContent;
