import React from "react";
import { useTranslation } from "react-i18next";
import NoticationClose from "src/components/SvgIcon/NoticationClose";

interface Props {
    modalKey: number;
    description: string;
    close: (modalKey: number) => void;
    ok: () => void;
}

const Confirm : React.FC<Props> = ({
    modalKey,
    close,
    ok,
    description,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ai-modal-confirm">
          <div className="ai-modal-mask"></div>

          <div className="ai-modal-content">
            <div className="title"></div>
            <div className="text">
              {description}
            </div>
            <NoticationClose
              onClick={() => {
                close(modalKey);
              }}
            />
            <div className="btn-groups">
              <div className="cancel-btn btn" onClick={() => close(modalKey)}>
                {t("Trade.Cancel")}
              </div>
              <div
                className="confirm-btn btn"
                onClick={ok}
              >
                {t("Trade.Yes")}
              </div>
            </div>
          </div>
        </div>
    )
}

export default Confirm;