import React from "react";
import classNames from "classnames";
import NoticationClose from "../SvgIcon/NoticationClose";

import "./modal.scss";

export interface Props {
  className?: string;
  title?: string | React.ReactNode;
  style?: React.CSSProperties;
  children: React.ReactNode;
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const Modal: React.FC<Props> = ({
  open,
  onCancel,
  onOk,
  title,
  className,
  children,
  style,
}) => {
  return (
    <div
      className={classNames("ai-modal", className, {
        open: open,
      })}
    >
      <div className="ai-modal-mask"></div>
      <div className="ai-modal-content-wrap" style={style}>
        <div className="ai-modal-title">
          <span className="title-text">{title}</span>
          <NoticationClose
            className="close-btn"
            onClick={() => {
              onCancel();
            }}
          />
        </div>
        <div className="ai-modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
