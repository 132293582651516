import React, { useEffect, useRef } from "react";
import { colors } from "src/utils/index";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import anime from "animejs/lib/anime.es.js";
import { number } from "echarts";
import { duration } from "moment";

export const Multipleani = (multipleListRef) => {
  if (multipleListRef.current) {
    const firstItem = multipleListRef.current.firstChild as HTMLElement;
    const otherItems = Array.from(multipleListRef.current.children).slice(1) as HTMLElement[];
    if (firstItem && otherItems) {
      firstItem.style.visibility = "hidden";
      anime({
        delay: 3000,
        complete: () => {
          firstItem.style.visibility = "visible";
        },
      });
      anime({
        targets: firstItem,
        translateX: [
          { value: -40, duration: 0, easing: "easeInOutSine" },
          { value: 0, duration: 1000, easing: "easeInOutSine" },
        ],
        scale: [
          { value: 2, duration: 0, easing: "easeInOutSine" },
          { value: 1, duration: 1000, easing: "easeInOutSine" },
        ],
        easing: "easeInOutQuad",
        delay: 1000,
      });
      otherItems.forEach((item)=>{
        const width=item.getBoundingClientRect().width;
        anime({
          targets: otherItems,
          translateX: [
            { value: -width, duration: 0, easing: "easeInOutSine" },
            { value: 0, duration: 1000, easing: "easeInOutSine" },
          ],
          easing: "easeInOutQuad",
          delay: 1000,
        });
      }) 
    }
  }
};

const HeaderBottomContent = ({
  multipleDropDown,
  multiple,
  openMultipleDropDown,
  multipleList,
  multipleListRef,
}) => {
  const { t } = useTranslation();
  setTimeout(() => {}, 0);
  return (
    <div className="header-bottom-content">
      <div className="wrap">
        {!multipleDropDown && (
          <>
            <ul className="multiple" ref={multipleListRef}>
              {multiple.map((v) => {
                return (
                  <li
                    className="multiple-item"
                    style={{
                      color: colors(v.value),
                    }}
                  >
                    {v.label}
                  </li>
                );
              })}
            </ul>
            <div className="divider"></div>
          </>
        )}
        {multipleDropDown && (
          <div className="round-history">
            {t("HeaderBottomContent.ROUND_HISTORY")}
          </div>
        )}
        <div className={classNames("multiple-dropdown-select")}>
          <div
            className={classNames("select-item", {
              open: multipleDropDown,
            })}
            onClick={() => openMultipleDropDown()}
          >
            <div className="clock"></div>
            <div className="icon-three-arrow"></div>
          </div>
        </div>
      </div>

      <div
        className={classNames("select-dropdown-list", {
          open: multipleDropDown,
        })}
      >
        {multipleList.map((v) => {
          return (
            <div
              className="select-dropdown-list-item-td"
              style={{
                color: colors(v.value),
              }}
            >
              {v.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderBottomContent;
