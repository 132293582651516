import React, {useEffect, useRef} from "react";
import classNames from "classnames";
import { createChart } from 'lightweight-charts'

const LightWeightChart = (props, _ref) => {
    const ref = useRef(null);
    const series = useRef(null);
    const chart = useRef(null);

    React.useImperativeHandle(_ref, () => ({
        updatePoint: (data) => {
            if (series.current) {
                series.current.update(data);
                // chart.current.timeScale().fitContent();
            }
        },
        clear: () => {
            if (series.current) {
                // console.log(series.current)
                series.current.setData([]);
            }
        },
        setData: (data) => {
            if (series.current) {
                // console.log(series.current)
                series.current.setData(data);
            }
        }
    }))

    useEffect(() => {
        const chartOptions: any = {
            layout: { attributionLogo: false, textColor: '#aaa', background: { type: 'solid', color: '#000' } }, // 文字颜色为白色, 背景为黑色
            grid: { vertLines: { color: 'rgba(51, 59, 71, 0.3)' }, horzLines: { color: 'rgba(51, 59, 71, 0.3)' } }, // 横轴纵轴的分割线颜色
            // handleScroll: false,
            // handleScale: false,
          }
        
        const container = ref.current;
        // let chart = null;
        if (!chart.current) {
            chart.current = createChart(container, chartOptions);
            series.current = chart.current.addCandlestickSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
                borderVisible: false,
                wickUpColor: '#26a69a',
                wickDownColor: '#ef5350',
            });
    
            // series.current.setData([
            //     {
            //         time: 1514894400,
            //         close: 20,
            //         open: 25,
            //         low: 20,
            //         high: 35,
            //     }
            // ] as any)
            // chart.current.timeScale().fitContent();
            chart.current.timeScale().applyOptions({
                // 隐藏x轴标签
                visible: false,
                rightOffset: 5,
                // barSpacing: 20
            });

            // 监听容器大小变化，重新resize
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    // console.log(entry.contentBoxSize[0].blockSize)
                  chart.current.resize(entry.contentBoxSize[0].inlineSize, entry.contentBoxSize[0].blockSize);
                }
            });
            resizeObserver.observe(ref.current);
        }
        
    }, [])

    return (
        <div
            className={classNames("app-chart")}
            id="app-chart"
            ref={(node) => (ref.current = node)}

      ></div>
    )
}

export default React.forwardRef(LightWeightChart);